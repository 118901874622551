@import './index';

:root {
  --input-font-size: 14px;

  @media (--lte-tablet) {
    --input-font-size: 16px;
  }
}

body {
  --body-margin-top: 60px;
}

main {
  margin-top: 0;
}

nav {
  height: 60px;
}

th {
  text-align: left;
}

.nav__logo img {
  height: 36px;
  width: 131px;
}

.right {
  float: right;
}
